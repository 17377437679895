import PromisePollyfill from 'promise-polyfill';
var local = {};

if (typeof window !== 'undefined') {
  local = window;
} else if (typeof self !== 'undefined') {
  local = self;
}

var Promise = 'Promise' in local ? local.Promise : PromisePollyfill;
export { Promise };