import ErrorLogging from './error-logging';
export default {
  ErrorLogging: ErrorLogging,
  registerServices: function registerServices(serviceFactory) {
    serviceFactory.registerServiceCreator('ErrorLogging', function () {
      var apmService = serviceFactory.getService('ApmServer');
      var configService = serviceFactory.getService('ConfigService');
      var transactionService = serviceFactory.getService('TransactionService');
      return new ErrorLogging(apmService, configService, transactionService);
    });
  }
};