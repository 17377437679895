import ApmServer from './apm-server';
import ConfigService from './config-service';
import LoggingService from './logging-service';
import { CONFIG_CHANGE } from './constants';

var ServiceFactory = function () {
  function ServiceFactory() {
    this._serviceCreators = {};
    this._serviceInstances = {};
    this.initialized = false;
  }

  var _proto = ServiceFactory.prototype;

  _proto.registerCoreServices = function registerCoreServices() {
    var serviceFactory = this;
    this.registerServiceCreator('ConfigService', function () {
      return new ConfigService();
    });
    this.registerServiceCreator('LoggingService', function () {
      return new LoggingService({
        prefix: '[Elastic APM] '
      });
    });
    this.registerServiceCreator('ApmServer', function () {
      return new ApmServer(serviceFactory.getService('ConfigService'), serviceFactory.getService('LoggingService'));
    });
  };

  _proto.init = function init() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    var configService = this.getService('ConfigService');
    configService.init();
    var loggingService = this.getService('LoggingService');

    function setLogLevel(loggingService, configService) {
      var debug = configService.get('debug');
      var logLevel = configService.get('logLevel');

      if (debug === true && logLevel !== 'trace') {
        loggingService.setLevel('debug');
      } else {
        loggingService.setLevel(logLevel);
      }
    }

    setLogLevel(loggingService, configService);
    configService.events.observe(CONFIG_CHANGE, function () {
      setLogLevel(loggingService, configService);
    });
    var apmServer = this.getService('ApmServer');
    apmServer.init();
  };

  _proto.registerServiceCreator = function registerServiceCreator(name, creator) {
    this._serviceCreators[name] = creator;
  };

  _proto.registerServiceInstance = function registerServiceInstance(name, instance) {
    this._serviceInstances[name] = instance;
  };

  _proto.getService = function getService(name) {
    if (!this._serviceInstances[name]) {
      if (typeof this._serviceCreators[name] === 'function') {
        this._serviceInstances[name] = this._serviceCreators[name](this);
      } else {
        throw new Error('Can not get service, No creator for: ' + name);
      }
    }

    return this._serviceInstances[name];
  };

  return ServiceFactory;
}();

export default ServiceFactory;